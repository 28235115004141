import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import common from "common/js/common";
import { empMarket, empMarketAnon, addShareEmpToAsk, addRecommendEmp } from "api/aiworker/aiworker.js";
export default {
  data: function data() {
    return {
      iflag: false,
      isrc: '',
      btnloading: false,
      modelList: [],
      workbenchesStaffArr: [],
      shareList: [],
      btnimg2: 'https://cdn.wiseai.chat/APP/sys_img/digitalEmployee/aidhh.png'
    };
  },
  computed: {
    access_token: function access_token() {
      return this.$store.state.user.access_token;
    }
  },
  methods: {
    openConversion: function openConversion(index) {
      if (common.isStringEmpty(this.access_token)) {
        this.$emit("openloginAction", 2);
      } else {
        this.iflag = true;

        if (index == 1) {
          this.isrc = this.$currentServe + 'conversation/#/?' + this.modelList[0].empMarket[0].empNo;
        } else {
          this.isrc = this.$currentServe + 'conversation/#/?' + index;
        }

        sessionStorage.setItem("empLocation", "2");
      }
    },
    createEmp: function createEmp(index) {
      if (common.isStringEmpty(this.access_token)) {
        this.$emit("openloginAction", 1);
      } else {
        if (index == 1) {
          // window.open(this.$currentServe + "backend/#/emp/myyg");
          top.location.href = this.$currentServe + "backend/#/emp/myyg";
        }
      }
    },
    closeConver: function closeConver() {
      this.iflag = false;
    },
    refreshModel2: function refreshModel2(index, id) {
      if (common.isStringEmpty(this.access_token)) {
        this.getempMarketAnnoList();
      } else {
        this.getempMarketList(index, id);
      }
    },
    shareEmpBtn: function shareEmpBtn(id1, item, id) {
      if (common.isStringEmpty(this.access_token)) {
        this.$emit("openloginAction", 4, id1);
      } else {
        this.shareEmpAction(item, id);
      }
    },
    createSameEmpBtn: function createSameEmpBtn(item, id) {
      if (common.isStringEmpty(this.access_token)) {
        this.$emit("openloginAction", 5, id);
      } else {
        this.createSameEmpAction(item, id);
      }
    },
    createSameEmpAction: function createSameEmpAction(item, id) {
      var _this = this;

      addRecommendEmp(id).then(function (res) {
        console.log(res);

        if (res.code == 200) {
          _this.$message({
            message: '创建成功',
            type: 'success',
            duration: 1000,
            onClose: function onClose() {
              //此处写提示关闭后需要执行的函数
              top.location.href = _this.$currentServe + "backend/#/emp/myyg";
            }
          });
        } else {
          if (!common.isStringEmpty(res.message)) {
            _this.$message.error(res.message);
          }
        }
      }).catch(function (err) {
        if (!common.isStringEmpty(JSON.stringify(err))) {
          _this.$message.error(JSON.stringify(err.message));
        }
      });
    },
    shareEmpAction: function shareEmpAction(item, id) {
      var _this2 = this;

      item.btnloading = true;
      addShareEmpToAsk(id).then(function (res) {
        console.log(res);
        item.btnloading = false;

        if (res.code == 200) {
          _this2.openConversion(res.data.empNo);
        } else {
          if (!common.isStringEmpty(res.message)) {
            _this2.$message(res.message);
          }
        }
      }).catch(function (err) {
        item.btnloading = false;

        if (!common.isStringEmpty(JSON.stringify(err))) {
          _this2.$message(JSON.stringify(err.message));
        }
      });
    },
    getempMarketAnnoList: function getempMarketAnnoList() {
      var _this3 = this;

      empMarketAnon().then(function (res) {
        console.log(res);

        if (res.code == 200) {
          _this3.modelList = res.data;

          _this3.modelList.map(function (item) {
            _this3.$set(item, 'btnloading', false);

            _this3.$set(item, 'btnloading2', false);

            return item;
          });
        } else {
          if (!common.isStringEmpty(res.message)) {
            _this3.$message(res.message);
          }
        }
      }).catch(function (err) {
        if (!common.isStringEmpty(JSON.stringify(err))) {
          _this3.$message(JSON.stringify(err.message));
        }
      });
    },
    getempMarketList: function getempMarketList(index, id) {
      var _this4 = this;

      empMarket(index, id).then(function (res) {
        console.log(res);

        if (res.code == 200) {
          _this4.modelList = res.data;

          _this4.modelList.map(function (item) {
            _this4.$set(item, 'btnloading', false);

            _this4.$set(item, 'btnloading2', false);

            return item;
          });

          if (index == -1) {
            console.log("indexindex" + index);
            _this4.iflag = true;
            _this4.isrc = _this4.$currentServe + 'conversation/#/?' + _this4.modelList[0].empMarket[0].empNo;
          } else if (index = 1) {
            console.log("indexindexindexindex" + index);

            for (var i = 0; i < _this4.modelList.length; i++) {
              for (var j = 0; j < _this4.modelList[i].empMarket.length; j++) {
                if (id == _this4.modelList[i].empMarket[j].empId) {
                  //shareEmpBtn(item,item2.shareEmpId)
                  _this4.shareEmpAction(_this4.modelList[i], _this4.modelList[i].empMarket[j].shareEmpId);

                  return false;
                }
              }
            }
          } else if (index == 2) {
            for (var _i = 0; _i < _this4.modelList.length; _i++) {
              for (var _j = 0; _j < _this4.modelList[_i].empMarket.length; _j++) {
                if (id == _this4.modelList[_i].empMarket[_j].empId) {
                  //shareEmpBtn(item,item2.shareEmpId)
                  _this4.createSameEmpAction(_this4.modelList[_i], _this4.modelList[_i].empMarket[_j].empId);

                  return false;
                }
              }
            }
          }
        } else {
          if (!common.isStringEmpty(res.message)) {
            _this4.$message(res.message);
          }
        }
      }).catch(function (err) {
        if (!common.isStringEmpty(JSON.stringify(err))) {
          _this4.$message(JSON.stringify(err.message));
        }
      });
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created: function created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted: function mounted() {
    console.log("modelmodelmodelmodelmodel");

    if (common.isStringEmpty(this.access_token)) {
      this.getempMarketAnnoList();
    } else {
      this.getempMarketList(0);
    }
  }
};